import { Box } from '@mui/material'
import ExtraInfoGuestForm from '~/src/components/auth/extra-info/guest/ExtraInfoGuestForm'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'

const ExtraInfoGuest = () => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: 5
      }}
    >
      <Box>
        <Title variant='h3'>
          {t('extraInfo.guest.continueAsGuest')}
        </Title>
        <Title variant='h6'>
          {t('extraInfo.guest.moreInformations')}
        </Title>
      </Box>
      <ExtraInfoGuestForm />
    </Box>
  )
}

export default ExtraInfoGuest
