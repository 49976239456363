import { useMemo } from 'react'
import FormParams from '~/src/types/forms/FormParams'
import { GuestFormInterface } from '~/src/types/forms/GuestFormInterface'
import * as yup from 'yup'
import { useTranslations } from '~/src/hooks/useTranslations'
import { useEoValue } from '~/src/hooks/useEoState'
import { AUTHENTICATION_STATE } from '~/src/stores/authentication'
import useGuestFormFields from '~/src/hooks/forms/guest-form/useGuestFormFields'

const useGuestForm = (): FormParams<GuestFormInterface> => {
  const authState = useEoValue(AUTHENTICATION_STATE)
  const { getFieldRequiredValidator, getPhoneFieldValidator } = useGuestFormFields()
  const { t } = useTranslations()

  const initialValues = {
    email: authState?.guest?.email || '',
    firstName: authState?.guest?.firstName || '',
    lastName: authState?.guest?.lastName || '',
    phone: authState?.guest?.phone || '',
    society: authState?.guest?.society || ''
  }

  const validationSchema = useMemo(() => {
    return yup.object({
      email: getFieldRequiredValidator('email', {
        baseSchema: yup
          .string()
          .email(t('errors.emailInvalid')),
        requiredMessage: t('errors.emailRequired')
      }),
      firstName: getFieldRequiredValidator('firstName', {
        baseSchema: yup.string(),
        requiredMessage: t('errors.firstNameRequired')
      }),
      lastName: getFieldRequiredValidator('lastName', {
        baseSchema: yup.string(),
        requiredMessage: t('errors.lastNameRequired')
      }),
      phone: getPhoneFieldValidator('phone', {
        baseSchema: yup.string(),
        requiredMessage: t('errors.phoneRequired'),
        invalidFormatMessage: t('errors.phoneWrongFormat')
      }),
      society: getFieldRequiredValidator('company', {
        baseSchema: yup.string(),
        requiredMessage: t('errors.companyRequired')
      })
    })
  }, [ getFieldRequiredValidator, getPhoneFieldValidator ])

  return { initialValues, validationSchema }
}

export default useGuestForm
